export function getRandomIntBetween (min: number, max: number): number {  
  return Math.floor(getRandomNumberBetween(min, max));
}

export function getRandomNumberBetween (min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export function getNumberOfRandom (array: any[], n: number) {
  var result = new Array(n),
      len = array.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = array[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}