import React from "react";
import logo from "./assets/logo.svg";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Image,
  Button,
} from "react-bootstrap";
import "./App.css";
import { RockCardGenerator } from "./components/RockCardGenerator/RockCardGenerator";
import { RockImageGrid } from "./components/RockImageGrid/RockImageGrid";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <Navbar bg="light" fixed="top">
        <Navbar.Brand href="#home">PRP</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#support-us">Support Us</Nav.Link>
          <Nav.Link href="#our-mission">Our Mission</Nav.Link>
        </Nav>
      </Navbar>
      <Container fluid>
        <Row id="home">
          <div className="hero-image">
            <div className="hero-text">
              <Image src={logo} width="200px" />
              <div>
                <h1>The Pet Rock Project</h1>
                <br></br>
                <h5>
                  We are a sanctuary for mistreated, abused, and forgotten pet
                  rocks.
                </h5>
                <br></br>
                <h5>
                  Providing them a safe haven for rehabilitation, we then
                  facilitate their reintegration into the modern home.
                </h5>
              </div>
            </div>
          </div>
        </Row>
        <Row id="support-us" className="justify-content-md-center">
          <Col md="auto">
            <div class="text-section-container">
              <div class="text-section">
                <h2>Sponsor for a Friend</h2>
                <p>
                  Have a lonely and depressed friend that could never be trusted
                  to take care of anything with a pulse and set of lungs? A
                  rescued pet rock provides the perfect combination of lacking
                  responsibility and half-assed affection. Sponsor a pet rock
                  and get a link to send their new pet directly to them!
                </p>
              </div>
            </div>
          </Col>
          <Col md="auto">
            <div class="text-section-container">
              <div class="text-section">
                <h2>Why Sponsor a Pet Rock?</h2>
                <p>
                  By sponsoring a neglected and down-on-its-luck pet rock,
                  you’ll not only gain a lifelong pet (this thing will
                  definitely outlive you; guaranteed) but you’ll be supporting
                  an internationally plagiarized NGO that is aimed at bettering
                  our planet, one small stone at a time. And what is more
                  important than that?
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md="auto">
            <RockCardGenerator />
          </Col>
        </Row>
        <Row className="donate-section dark-theme justify-content-md-center align-items-center">
          <Col md="auto">
            <RockImageGrid />
          </Col>
          <Col className="align-middle mb-50" md="auto">
            <div class="text-section-container shadow">
              <div class="text-section">
                <h3>
                  We need your support to carry on our meaningful work.<br></br>
                  <br></br>
                  Help us by sponsoring a rock <b>today</b>.
                </h3>
                <Button
                  className="rounded-0 font-bold"
                  href="https://checkout.square.site/pay/1eb956d0687c4ec3bdf8cf416397ac4b"
                  variant="success"
                >
                  Donate Now!
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row id="our-mission">
          <div class="text-section-container">
            <div class="text-section">
              <h2>History of the Pet Rock Project</h2>
              <p>
                In 1975, over a million pet rocks were bought and sold to
                careless owners with no long term vision of how to care for
                them. In this craze, responsible parents had finally found a
                safe way for their kids to get stoned, financially strapped
                boyfriends were alas able to afford a rock for their future
                fiancees, and masses of childhood nerds were finally able to use
                their pets to protect themselves from the bullies who were
                taking their lunch money. Rocks were the most versatile pet to
                hit the market since the domestication of wolves. They were all
                the rage.
                <br></br>
                <br></br>
                But it was not all diamonds and rainbows, for it was when this
                shopping spree ended, that so did the comfortable living
                standards of all of the pet rocks purchased. Just like a
                forgotten toy left in the rain, many of these pet rocks were
                left to fend for themselves, finding that their fifteen minutes
                of fame had come and gone in a brief moment of stardom with
                individuals who never cared for them in the first place.
                <br></br>
                <br></br>
                They were either thrown back into the wild without any idea of
                how to survive, or left in environments far different than those
                that they had spent their previous 14 million years within.
                These pet rocks needed a life-line to get them back on their
                edge, and that's exactly what we at the Pet Rock Project aimed
                to do.
                <br></br>
                <br></br>
                It's really quite simple. Unlike cats, pet rocks weren't able to
                adapt to the modern technologies of Youtube and TikTok for
                monetization, meaning they were unable to meet the basic living
                standards that every piece of highly compressed molten stone
                requires. Without a steady stream of income giving them the
                purchasing power for a plot of land that they could slowly pack
                into a sedimentary solid over time, they were unable to live up
                to their purpose in the evolutionary cycle. Since the
                evolutionary departure of the Stone Age, many demographics of
                rocks had found themselves highly unemployable and out of work.
                The disparity in income between diamonds and other precious
                stones grew, leaving a high proportion of sedimentary solids
                adrift and searching for meaning.
                <br></br>
                <br></br>
                This is why once the opportunity to reimagine themselves as pets
                instead of outdated tools, most rocks jumped at the opportunity,
                seeing it as a way to finally reconnect with their Neanderthalic
                partner in mankind.
                <br></br>
                <br></br>
                In the beginning it was great. They were pleased to find that
                mankind's intellectual abilities had not grown far since the
                Neanderthalic era; thus, many took well to connecting with their
                age old comrades in a pet form. The rocks were able to do well
                in their new roles. It was a match made in heaven for the first
                few months, but like any fad, the rocks soon fell out of style,
                and in doing so, found themselves helpless and without much
                hope.
                <br></br>
                <br></br>
                This was until the conception of the unofficial organization
                that is the Pet Rock Project. We provide a safe-haven for the
                rehabilitation of every pet rock that was purchased, then passed
                up on, in the era of careless consumerism that gripped the
                1970's. We focus on rebuilding the wellbeing and confidence of
                these stones, preparing them for the introductions with their
                new families and their entrance into an age controlled by modern
                technologies and the internet. At the end of our rehabilitation
                process, the pet rocks are fully ready to be reintegrated into
                the modern home, then start serving out their days as the best
                pets for the twenty-first century.
                <br></br>
                <br></br>
                We at the Pet Rock Project believe that these hard and soulless
                little bundles of density weren't meant for the free living and
                high spirits of the twentieth century. They were a pet that took
                on fame just ahead of their time. Instead of fitting into the
                careless hands of 1970's hippies, they belong much more in the
                computer screens of tech-savvy wiz kids and late night
                Redditors.
                <br></br>
                <br></br>
                Pet rocks have adapted rather seamlessly to the wildly useless
                years of the twenty-first century, meeting all the banalities
                and stupidity of its time in perfect, satirical form. In the age
                of the meme and endless social media cycle, we believe that
                everyone should do well in sponsoring themselves a pet that even
                the largest of halfwits could take care of. With a boulder
                outlook on life and their future, the pet rock is joining the
                tech-tonic age.
                <br></br>
                <br></br>
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <div className="footer-section dark-theme">
            <Image src={logo} width="50px" />
            <p>
              Legal notice should hereby be stated that the Pet Rock Project is
              not a registered organization, nor is it affiliated with the
              original Pet Rock in any way. It operates as a website aimed at
              generating revenue. It is a parody. It is not an actual non for
              profit. It encourages all users to support the cause for their
              creative and timely efforts of its creators, but more importantly,
              the long and never ending fight that will be the rehabilitation
              and reconciliation with the Pet Rocks of 1975.
              <br></br>
              May they all find modern and tech-savvy homes.
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default App;
