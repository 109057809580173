import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import rock1 from '../../assets/rocks/rock1.jpg';
import rock2 from '../../assets/rocks/rock2.jpg';
import rock3 from '../../assets/rocks/rock3.jpg';
import rock4 from '../../assets/rocks/rock4.jpg';
import rock5 from '../../assets/rocks/rock5.jpg';
import rock6 from '../../assets/rocks/rock6.jpg';
import rock7 from '../../assets/rocks/rock7.jpg';
import rock8 from '../../assets/rocks/rock8.jpg';
import rock9 from '../../assets/rocks/rock9.jpg';
import './RockImageGrid.css';

export class RockImageGrid extends React.Component {
  render() {
    return (
      <Container className="grid-container">
        <Row>
          <Col>
            <Image src={rock1} roundedCircle />
          </Col>
          <Col >
            <Image src={rock2} roundedCircle />
          </Col>
          <Col >
            <Image src={rock3} roundedCircle />
          </Col>
        </Row>
        <Row>
          <Col >
            <Image src={rock4} roundedCircle />
          </Col>
          <Col >
            <Image src={rock5} roundedCircle />
          </Col>
          <Col >
            <Image src={rock6} roundedCircle />
          </Col>
        </Row>
        <Row>
          <Col >
            <Image src={rock7} roundedCircle />
          </Col>
          <Col >
            <Image src={rock8} roundedCircle />
          </Col>
          <Col >
            <Image src={rock9} roundedCircle />
          </Col>
        </Row>
      </Container>
    )
  }
}